<template>
  <div class="hero-head">
    <nav class="navbar">
      <div class="container">
        <div class="navbar-brand">
          <a class="navbar-item" href="#">
            <img
              src="../assets/logo.png"
              alt="Logo"
              style="margin-right: 1em"
            />
            ກຽງໄກຂົນສົ່ງດ່ວນ
          </a>
          <span
            class="navbar-burger burger"
            @click="isOpen = !isOpen">
            <span></span>
            <span></span>
            <span></span>
          </span>
        </div>
        <div class="navbar-menu " :class="{ 'is-active': isOpen }">
          <div class="navbar-end">
            <span
              class="navbar-item"
                  @click="isOpen = !isOpen"
              v-for="(item, index) in items"
              :key="index">
              <b-button tag="router-link"
          
                :to="`/${item.route}`"
                type="is-white"
                class="button is-outlined is-white is-fullwidth"
                :icon-left="item.icon"
              >
                {{ item.label }}
              </b-button>
            </span>
          </div>
        </div>
      </div>
    </nav>
  </div>
</template>

<script>
export default {
  name: "MenuPublic",
  data: () => {
    return {
      isOpen:false,
      showMenu: "",
      items: [
        {
          label: "ໜ້າຫຼັກ",
          icon: "home",
          route: "index",
        },
        {
          label: "ສາຂາ/ຈຸດຮັບຝາກເຄື່ອງ",
          icon: "source-branch",
          route: "branch",
        },
        {
          label: "ລາຄາຂົນສົ່ງ",
          icon: "cash",
          route: "price",
        },
        {
          label: "ເຂົ້າສູ່ລະບົບ",
          icon: "lock",
          route: "login",
        },
      ],
    };
  },
  methods: {
    toggleMenu() {
      if (this.showMenu == "") this.showMenu = "is-active";
      else this.showMenu = "";
    },
    
  },
};
</script>
<style>
@media screen and (min-width: 1023px) {
  .hero.is-info .navbar-menu {
    background-color: transparent;
  }
}
@media screen and (max-width: 1023px) {
  .navbar-burger{
     color: #FFF !important ;
  }
  .button.is-white.is-outlined {
    color: #000 !important ;
  }
  .button.is-white.is-outlined{
    border-color:rgba(0, 0, 0, 0.342)
  }
  .hero.is-info .navbar-menu {
    background-color: #F9EA2F;
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
    border-top: 2px solid rgba(109, 109, 109, 0.842);
    box-shadow: 0 8px 8px rgb(10 10 10 / 10%);
    left: 0;
    min-width: 100%;
    position: absolute;
    top: 100%;
  }
}
</style>