<template>
  <section class="hero is-info is-fullheight">
    <menu-public />
    <div :class="currentRouteName==='/policy'?'':'hero-body'">
      <router-view />
    </div>
  </section>
</template>
<script>
import MenuPublic from "../components/MenuPublic.vue";
export default {
  components: {
    MenuPublic,
  },
  computed: {
    currentRouteName() {
      //console.log(this.$route.path);
        return this.$route.path;
    }
}
};
</script>

<style scoped>
html,
body {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
}
.hero.is-info {
  background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url("../../public/img/slide1.jpeg") no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}
.hero .nav,
.hero.is-success .nav {
  -webkit-box-shadow: none;
  box-shadow: none;
}
.hero .subtitle {
  padding: 3rem 0;
  line-height: 1.5;
}
</style>